<template>
  <div>
     <el-dialog
      title="资质申请"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
      @open="opDialog"
    >
      <el-form ref="addform" :model="form" label-width="120px"  size="mini" :rules="rule" >
        <div >
          <el-form-item label="施工队类型" prop="typeCode">
           <el-select v-model="form.typeCode"  placeholder="请选择" class="SearchOptions" v-if="form.typeEditFlag==1">
                <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.name"
                
                :value="item.value">
                </el-option>
            </el-select>
             <el-select v-model="form.typeCode" disabled placeholder="请选择" class="SearchOptions" v-else>
                <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.name"
                
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="施工队名称" prop="teamName">
           <el-input type="text" v-model="form.teamName" name="limitLength" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contactUser">
            <el-input type="text" v-model="form.contactUser" name="limitLength" maxlength="50"></el-input>
          </el-form-item>
         
          <el-form-item label="联系电话" prop="contactPhone">
            <el-input type="text" v-model="form.contactPhone" ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" >
        <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button>
        <el-button type="success" @click="saveadd">保存</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import {constructionTeam_save,constructionTeam_getDetail,constructionTeam_update} from "../../RequestPort/apply/team.js"
export default {
  components: { },
  name: 'applyList',
  mixins: [],
  data() {
    return {
      dialogVisible:false,
      rule:{
         typeCode:{ required: true, message: "请选择施工队类型", trigger: "blur" },
         teamName:{ required: true, message: "请输入施工队名称", trigger: "blur" },
         contactUser:{ required: true, message: "请输入联系人", trigger: "blur" },
         contactPhone:[{ required: true, message: "请输入联系人电话", trigger: "blur",pattern:/^[1][3,4,5,6,7,8,9][0-9]{9}$/},

         ]
      },
      form:{typeEditFlag:1},
      typeList:[{value:'1',name:'安装'},{value:'2',name:'土方'},{value:'3',name:'定向钻'},{value:'4',name:'其他'}]
    }
  },
  props: {
    source: {
      type: Number,
    },
    id:{
      type:String,
      default:'0'
    }
  },
  mounted() {
      
  },
  methods: {
      opDialog(){
        if(this.source==2){
          constructionTeam_getDetail({id:this.id}).then(res=>{
          this.form = res.data;
          this.form.typeCode = this.form.typeCode+''
        })
        }
        
      },
      clearnF(){
          this.form = {
              teamName:'',
              contactUser:'',
              contactPhone:'',
              typeCode:'',
              typeEditFlag:1
          }
          if(this.$refs['addform']){
            this.$refs['addform'].resetFields();
          }
          
      },
      saveadd(){
          this.$refs['addform'].validate(valid => {
            if (valid) {
              if(this.source==1){
                constructionTeam_save(this.form).then(res=>{
                    this.$message.success('添加施工队成功！')
                    this.setdialogVisible(false);
                    if(this.source==1){
                        this.$parent.getTeam();
                    }
                })
              }else{
                constructionTeam_update(this.form).then(res=>{
                    this.$message.success('编辑施工队成功！')
                    this.setdialogVisible(false);
                    this.$parent.getList(1);
                    
                })
              }
                
            }
          })
      },
      setdialogVisible(flag){
          this.dialogVisible = flag;
      }
  }
}
</script>