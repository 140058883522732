<template>
  <div>
    <div class="content" @click='showtype=false'>
      <div class="all">
        <div class="SearchBar">
          <!--搜索框-->
          <el-form ref="search" :inline="true" :model="search" size="small" >
            <el-form-item label="第三方施工队" class="lab">
              <el-input
                v-model="search.teamName"
                placeholder="请输入第三方施工队"
                class="SearchInput"
                clearable
              ></el-input>
            </el-form-item>
            <span >
            <el-button type="primary" class="SearchBtn" size="small" @click="getList(1)">查询</el-button>
            <el-button type="primary" plain class="SearchBtn2" size="small" @click="reset">重置</el-button>
            </span>
            
          </el-form>
        </div>
        <div class="table">
            
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
              height="calc(100vh - 350px)"
            :stripe="true"
          >
           <el-table-column
                type="index"
                label="序号"
                width="70" align="center">
                </el-table-column>
            <el-table-column
              prop="teamName"
              label="第三方施工队"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="typeName"
              label="施工队类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="contactUser"
              label="联系人"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="contactPhone"
              label="联系电话"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="holdContract"
              label="存续合同"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="deptName"
              label="关联施工队"
              align="center"
              show-overflow-tooltip
            >
           
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left" width="400">
              <template slot-scope="{ row }">
                <el-button
                    type="primary"
                    size="small"
                    @click="editItem(row)"
                    >编辑</el-button>
                   <el-button 
                    type="primary"
                    size="small"
                    @click="addUser(row)"
                    >关联账号</el-button>
                    <el-button  v-if="row.deptId"
                    type="danger"
                    size="small" @click="editStatus(row,1)"
                    >停用账号 </el-button>
                    <el-button v-if="row.deptId"
                    type="success"
                    size="small" 
                    @click="editStatus(row,2)"
                    > 启用账号</el-button>
                    
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
          <el-pagination
            :current-page="search.current"
            :background="true"
            :page-sizes="[ 30, 50, 100]"
            :page-size="search.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="关联账号"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
      <el-form ref="addform" :model="form" label-width="100px" :rules="rule"  size="small">
        <div>
          <el-form-item label="关联施工队：" prop="deptId">
            <el-cascader :options="teamList" v-model="form.deptId" 
            :props="props"></el-cascader>
            
          </el-form-item>
         
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveadd">确 定</el-button>
      </div>
    </el-dialog>
    <teamAdd ref="teamAdd" :source="source" :id="id"></teamAdd>
  </div>
  
</template>
<script>
import {constructionTeam_page,user_selectSysDeptTreeList,constructionTeam_relationDept,constructionTeam_accountSetup} from "../../RequestPort/apply/team.js"
import {selectDictListByParentCode } from '@/apis/commonType'
import teamAdd from './teamAdd.vue'

export default {
  components: {teamAdd},
  name: 'applyList',
  mixins: [],
  data() {
    return {
      search: { current: 1, size: 100}, //搜索
      total: 10,
      tableData: [{enterWarehouseName:1},], //列表对象
      dialogVisible:false,
      form:{},
      rule:{
         teamName:{ required: true, message: "请输入施工队名称", trigger: "blur" },
         contactUser:{ required: true, message: "请输入联系人", trigger: "blur" },
         contactPhone:[{ required: true, message: "请输入联系人电话", trigger: "blur",pattern:/^[1][3,4,5,6,7,8,9][0-9]{9}$/},

         ]
      },
      teamList:[],
      props:{label:'name',value:'id'},
      id:'',
      source:2
      
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
     
    getList(current) {
      if (current) {
        this.search.current = 1
      }
      //查列表
      constructionTeam_page(this.search).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
   editItem(row){
     this.id = row.id+"";
     this.$refs.teamAdd.setdialogVisible(true);
   },
    //重置查询条件
    reset() {
      ;(this.search = {
        current: 1,
        size: 100,
      }),
        this.getList()
    },
    handleSizeChange(val) {
      this.search.size = val
      this.getList(1)
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.getList()
    },
    clearnF() {
      this.form = {
        deptId:[],
        id:0
      }
    },
    saveadd(){
      this.$refs['addform'].validate(valid => {
        if (valid) {
            if(this.form.deptId.length>0){
              let len = this.form.deptId.length-1;
              this.form.deptId = this.form.deptId[len]
            }
            constructionTeam_relationDept(this.form).then(res=>{
              if(res.code=='200'){
                this.$message.success('关联账号成功');
                this.clearnF()
                this.getList(1);
                this.dialogVisible=false;

              }
           })
        }
      })
    },
    addUser(row){
      this.form.id = row.id;
        user_selectSysDeptTreeList({code:'sgd'}).then(res=>{
          if(res.code==200){
           // this.teamList = res.data[0].children[0].children;
           this.teamList = res.data;
            this.dialogVisible = true;
          }
        })
    },
    editStatus(row,falg){
          var msg = falg==1?'停用账号':'启用账号'
          this.$confirm('确定要'+msg+'吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type: 'warning'
        }).then(() => {
          let params = {
            id: row.id,
            setupType:falg==1?0:1
          }
          constructionTeam_accountSetup(params).then(res => {
            if (res.code == 200) {
              this.$message.success(msg+'成功')
            }
            this.getList()
          })
        })
    }
  }
}
</script>

<style  lang="less" scoped>
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchInput {
  width: 100%;
}
.SearchOptions {
  width: 100%;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
   background-color: #ffffff;
}

.all {
  background-color:#f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 20px;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
</style>
